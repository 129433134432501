import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../../Assets/logo.png';
import logoAoto from '../../Assets/products-logo/18-AOTO – 2.png'; 
import logoAllsee from '../../Assets/products-logo/32_allsee – 3.png';
import logoAudac from '../../Assets/products-logo/33_Maxhub – 2.png';
import logoAudinate from '../../Assets/products-logo/34_Audinate – 3.png';
import logoBrightSign from '../../Assets/products-logo/01_Brightsign – 42.png';
import logoCatchBox from '../../Assets/products-logo/06_katch box – 2.png';
import logoCyviz from '../../Assets/products-logo/13_cyviz – 2.png';
import logoEarthworks from '../../Assets/products-logo/04_NE – 2.png';
import logoHarman from '../../Assets/products-logo/21_Harman – 2.png';
import logoLaia from '../../Assets/products-logo/08_laia – 2.png';
import logoMaxhub from '../../Assets/products-logo/25_Maxhub – 3.png';
import logoMicrobrackerts from '../../Assets/products-logo/14_Multibrackets – 2.png';
import logoNexmosphere from '../../Assets/products-logo/36_nexmosphere – 3.png';
import logoNetgear from '../../Assets/products-logo/35_Netgear – 3.png';
import logoNureva from '../../Assets/products-logo/07_Nureva – 2.png';
import logoNvictta from '../../Assets/products-logo/11_Nvictta – 2.png';
import logoPrysm from '../../Assets/products-logo/24_Prysm – 2.png';
import logoQSC from '../../Assets/products-logo/22_QSC – 2.png';
import logoSennheiser from '../../Assets/products-logo/09_Sen – 2.png';
import logoSiemon from '../../Assets/products-logo/37_siemon – 3.png';
import logoWyreStorm from '../../Assets/products-logo/10_Wyrestrom – 2.png';
import logoXilica from '../../Assets/products-logo/38_Xilica – 3.png';
import logoXpoScreens from '../../Assets/products-logo/39_XPO – 3.png';
import epr from '../../Assets/epr.pdf';


import tech1 from '../../Assets/aoto.png';
import tech2 from '../../Assets/allsee.png';
import tech3 from '../../Assets/audac.png';
import tech4 from '../../Assets/audinate.png';
import tech5 from '../../Assets/brightsign.png';
import tech6 from '../../Assets/catchbox.png';
import tech7 from '../../Assets/cyviz.png';
import tech8 from '../../Assets/earthworks.png';
import tech9 from '../../Assets/harman.png';
import tech10 from '../../Assets/laia.png';
import tech11 from '../../Assets/maxhub.png';
import tech12 from '../../Assets/multibrackets.png';
import tech13 from '../../Assets/netgear.png';
import tech14 from '../../Assets/nexmosphere.png';
import tech15 from '../../Assets/nureva.png';
import tech16 from '../../Assets/nviccta.png';
import tech17 from '../../Assets/prysm.png';
import tech18 from '../../Assets/qsc.png';
import tech19 from '../../Assets/sennheiser.png';
import tech20 from '../../Assets/siemon.png';
import tech21 from '../../Assets/wyrestorm.png';
import tech22 from '../../Assets/xilica.png';
import tech23 from '../../Assets/xposcreens.png';
import './Navbar.css';


function Navbar() {
  const location = useLocation();
  const [logoPath, setLogoPath] = useState(logo); // Default logo
  const [logoClass, setLogoClass] = useState('default-logo');

  // Function to set logo based on the current location
  useEffect(() => {
    switch (location.pathname) {
      case '/Products/Aoto':
        setLogoPath(logoAoto);
        setLogoClass('product-logo');
        break;
      case '/Products/Allsee':
        setLogoPath(logoAllsee);
        setLogoClass('product-logo');
        break;
      case '/Products/Audac':
        setLogoPath(logoAudac);
        setLogoClass('product-logo');
        break;
      case '/Products/Audinate':
        setLogoPath(logoAudinate);
        setLogoClass('product-logo');
        break;
      case '/Products/BrightSign':
        setLogoPath(logoBrightSign);
        setLogoClass('product-logo');
        break;
      case '/Products/CatchBox':
        setLogoPath(logoCatchBox);
        setLogoClass('product-logo');
        break;
      case '/Products/Cyviz':
        setLogoPath(logoCyviz);
        setLogoClass('product-logo');
        break;
      case '/Products/Earthworks':
        setLogoPath(logoEarthworks);
        setLogoClass('product-logo');
        break;
      case '/Products/Harman':
        setLogoPath(logoHarman);
        setLogoClass('product-logo');
        break;
      case '/Products/Laia':
        setLogoPath(logoLaia);
        setLogoClass('product-logo');
        break;
        case '/Products/Maxhub':
        setLogoPath(logoMaxhub);
        setLogoClass('product-logo');
        break;
      case '/Products/MultiBrackets':
        setLogoPath(logoMicrobrackerts);
        setLogoClass('product-logo');
        break;
      case '/Products/Nexmosphere':
        setLogoPath(logoNexmosphere);
        setLogoClass('product-logo');
        break;
      case '/Products/Netgear':
        setLogoPath(logoNetgear);
        setLogoClass('product-logo');
        break;
      case '/Products/Nureva':
        setLogoPath(logoNureva);
        setLogoClass('product-logo');
        break;
      case '/Products/Nvictta':
        setLogoPath(logoNvictta);
        setLogoClass('product-logo');
        break;
        case '/Products/Prysm':
          setLogoPath(logoPrysm);
          setLogoClass('product-logo');
          break;
      case '/Products/QSC':
        setLogoPath(logoQSC);
        setLogoClass('product-logo');
        break;
      case '/Products/Sennheiser':
        setLogoPath(logoSennheiser);
        setLogoClass('product-logo');
        break;
      case '/Products/Siemon':
        setLogoPath(logoSiemon);
        setLogoClass('product-logo');
        break;
      case '/Products/WyreStorm':
        setLogoPath(logoWyreStorm);
        setLogoClass('product-logo');
        break;
      case '/Products/Xilica':
        setLogoPath(logoXilica);
        setLogoClass('product-logo');
        break;
      case '/Products/XpoScreens':
        setLogoPath(logoXpoScreens);
        setLogoClass('product-logo');
        break;
      
      default:
        setLogoPath(logo);
        setLogoClass('default-logo');
    }
  }, [location.pathname]); // Update logo whenever the pathname changes\


  const getHref = () => {
    if (location.pathname === '/Products/Aoto') {
      return 'https://www.aoto.com/';

    } else if (location.pathname === '/Products/Allsee') {
      return 'https://www.allsee-tech.com/';
    } 
    else if (location.pathname === '/Products/Audac') {
      return 'https://audac.eu/';
    } 
    else if (location.pathname === '/Products/Audinate') {
      return 'https://www.audinate.com/';
    } 
    else if (location.pathname === '/Products/BrightSign') {
      return 'https://www.brightsign.biz/';
    } 
    else if (location.pathname === '/Products/CatchBox') {
      return 'https://catchbox.com/';
    } 
    else if (location.pathname === '/Products/Cyviz') {
      return 'https://www.cyviz.com/';
    } 
    else if (location.pathname === '/Products/Harman') {
      return 'https://www.harman.com/';
    } 
    else if (location.pathname === '/Products/Maxhub') {
      return 'https://www.maxhub.com/in/';
    } 
    else if (location.pathname === '/Products/MultiBrackets') {
      return 'https://products.multibrackets.com/en/';
    } 
    else if (location.pathname === '/Products/Nexmosphere') {
      return 'https://www.nexmosphere.com/';
    } 
    else if (location.pathname === '/Products/Netgear') {
      return 'https://www.netgear.com/';
    } 
    else if (location.pathname === '/Products/Nureva') {
      return 'https://www.nureva.com/';
    } 
    else if (location.pathname === '/Products/Nvictta') {
      return 'http://nvictta.com/';
    } 
    else if (location.pathname === '/Products/Prysm') {
      return 'https://www.prysmsystems.com/';
    } 
    else if (location.pathname === '/Products/QSC') {
      return 'https://www.qsc.com/';
    } 
    else if (location.pathname === '/Products/Sennheiser') {
      return 'https://www.sennheiser.com/en-in';
    } 
    else if (location.pathname === '/Products/Siemon') {
      return 'https://www.siemon.com/en/';
    } 
    else if (location.pathname === '/Products/WyreStorm') {
      return 'https://www.wyrestorm.com/';
    } 
    else if (location.pathname === '/Products/Xilica') {
      return 'https://www.xilica.com/';
    } 
    else if (location.pathname === '/Products/XpoScreens') {
      return 'https://www.xposcreens.com/';
    } else {
      return '/'
    }
  };

  const [IsScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navlinkStyles = ({ isActive }) => ({
    textDecoration: "none",
    color: isActive ? "#05A54E" : "#0E0E0E",
    fontSize: "22px",
  });

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const [openDropdown, setOpenDropdown] = useState(null);

const toggleDrop = (index) => {
  setOpenDropdown(openDropdown === index ? null : index);
};


  return (
    <>
      {/* Desktop Navbar */}
      <nav className={`navbar ${IsScrolled ? 'navbar-scrolled' : ''} navbar navbar-expand-sm bg-light navbar-light d-none d-md-flex`}>
        <div className="cust-container d-flex w-100 justify-content-between align-items-center">
          {/* Navbar Brand (Logo) on the Left */}
          <a className="navbar-brand" href={getHref()}>
            <img loading="lazy" src={logoPath} className={logoClass} alt="Logo" />
          </a>

        {/* Navbar Links in the Center */}
        <div className="collapse navbar-collapse d-flex justify-content-center align-items-center" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item d-flex align-items-center">
                <NavLink style={navlinkStyles} className="navlinkstyles" to="/">Home</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center">
                <NavLink style={navlinkStyles} className="navlinkstyles" to="/About">About</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center">
                <NavLink style={navlinkStyles} className="navlinkstyles" to="/Products">
                      {/* Products <FontAwesomeIcon icon={faChevronDown} color="#0E0E0E" /> */}
                  <li className="nav-item dropdown-desktop">
                  <NavLink
                    style={{ ...navlinkStyles, paddingLeft: '05px', paddingRight: '05px' }} // Adjust padding as needed
                    className="navlinkstyles"
                    to="/Products"
                  >
                Products <FontAwesomeIcon icon={faChevronDown} color="#0E0E0E" />
              </NavLink>


                    <div className="dropdown-menu-desktop">
                      <div className='row'>
                        <div className='col-md-3'>
                          <NavLink className="dropdown-item" to="/Products/Aoto"><img loading="lazy" src={tech1} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Allsee"><img loading="lazy" src={tech2} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Audac"><img loading="lazy" src={tech3} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Audinate"><img loading="lazy" src={tech4} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/BrightSign"><img loading="lazy" src={tech5} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/CatchBox"><img loading="lazy" src={tech6} alt='aoto'></img></NavLink>
                         
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-3'>
                          <NavLink className="dropdown-item" to="/Products/Cyviz"><img loading="lazy" src={tech7} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Earthworks"><img loading="lazy" src={tech8} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Harman"><img loading="lazy" src={tech9} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Laia"><img loading="lazy" src={tech10} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Maxhub"><img loading="lazy" src={tech11} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/MultiBrackets"><img loading="lazy" src={tech12} alt='aoto'></img></NavLink>
                          
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-3'>
                        <NavLink className="dropdown-item" to="/Products/Netgear"><img loading="lazy" src={tech13} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Nexmosphere"><img loading="lazy" src={tech14} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Nureva"><img loading="lazy" src={tech15} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Nvictta"><img loading="lazy" src={tech16} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Prysm"><img loading="lazy" src={tech17} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/QSC"><img loading="lazy" src={tech18} alt='aoto'></img></NavLink>
                          
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-3'>
                        <NavLink className="dropdown-item" to="/Products/Sennheiser"><img loading="lazy" src={tech19} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Siemon"><img loading="lazy" src={tech20} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/WyreStorm"><img loading="lazy" src={tech21} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/Xilica"><img loading="lazy" src={tech22} alt='aoto'></img></NavLink>
                          <NavLink className="dropdown-item" to="/Products/XpoScreens"><img loading="lazy" src={tech23} alt='aoto'></img></NavLink>
                        </div>
                      </div>
                    </div>
                  </li>
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center">
                <NavLink style={navlinkStyles} className="navlinkstyles" to="/Services">Services</NavLink>
              </li>
              <li className="nav-item d-flex align-items-center">
                <NavLink style={navlinkStyles} className="navlinkstyles" to="/Careers">Careers</NavLink>
              </li>
            </ul>
          </div>

          {/* Right-Aligned Navbar Links */}
          <ul className="nav-right d-flex mb-0">
          <li className="nav-item">
  <NavLink 
    id="EPR" 
    style={navlinkStyles} 
    className="navlinkstyles" 
    to={epr} // Adjust this path according to the file location
    target="_blank" 
    rel="noopener noreferrer"
  >
    EPR
  </NavLink>
</li>
            <li className="nav-item">
              <NavLink  style={navlinkStyles} className="navlinkstyles button_111" to="/Contact">Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="navbar-mobile d-flex d-md-none">
        <div className="cust-mobile-container d-flex w-100 justify-content-between align-items-center">
          {/* Navbar Brand (Logo) inside mobile menu */}
          <a className="navbar-brand" href="/">
            <img loading="lazy" src={logoPath} alt="Logo" />
          </a>

          {/* Toggle Button for Mobile Screens */}
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
          </button>
        </div>

        {/* Collapsible Mobile Menu */}
              {isMenuOpen && (
          <div className="mobile-menu">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="navlinkstyles" to="/" onClick={toggleMenu}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="navlinkstyles" to="/About" onClick={toggleMenu}>About</NavLink>
              </li>
              
              {/* Products Dropdown */}
              <li className="nav-item dropdown">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="dropdown-item"
                    to="/Products"
                    style={{ fontSize: "22px", padding: "0 0 0 0" }}
                    onClick={toggleMenu}
                  >
                    Products
                  </NavLink>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#0E0E0E"
                    style={{ cursor: "pointer", marginLeft: "-67px", height: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleDrop(0); // Index for Products dropdown
                    }}
            />
          </div>
          <div className={`dropdown-menu ${openDropdown === 0 ? 'show' : ''}`}>
            <NavLink className="dropdown-item" to="/Products/Aoto" style={{ fontSize: "20px" }} onClick={toggleMenu}>Aoto</NavLink>
            <NavLink className="dropdown-item" to="/Products/Allsee" style={{ fontSize: "20px" }} onClick={toggleMenu}>Allsee</NavLink>
            <NavLink className="dropdown-item" to="/Products/Audac" style={{ fontSize: "20px" }} onClick={toggleMenu}>Audac</NavLink>
            <NavLink className="dropdown-item" to="/Products/Audinate" style={{ fontSize: "20px" }} onClick={toggleMenu}>Audinate</NavLink>
            <NavLink className="dropdown-item" to="/Products/BrightSign" style={{ fontSize: "20px" }} onClick={toggleMenu}>BrightSign</NavLink>
            <NavLink className="dropdown-item" to="/Products/CatchBox" style={{ fontSize: "20px" }} onClick={toggleMenu}>CatchBox</NavLink>
            <NavLink className="dropdown-item" to="/Products/Cyviz" style={{ fontSize: "20px" }} onClick={toggleMenu}>Cyviz</NavLink>
            <NavLink className="dropdown-item" to="/Products/Earthworks" style={{ fontSize: "20px" }} onClick={toggleMenu}>Earthworks</NavLink>
            <NavLink className="dropdown-item" to="/Products/Harman" style={{ fontSize: "20px" }} onClick={toggleMenu}>Harman</NavLink>
            <NavLink className="dropdown-item" to="/Products/Laia" style={{ fontSize: "20px" }} onClick={toggleMenu}>Laia</NavLink>
            <NavLink className="dropdown-item" to="/Products/Maxhub" style={{ fontSize: "20px" }} onClick={toggleMenu}>Maxhub</NavLink>
            <NavLink className="dropdown-item" to="/Products/MultiBrackets" style={{ fontSize: "20px" }} onClick={toggleMenu}>MultiBrackets</NavLink>
            <NavLink className="dropdown-item" to="/Products/Nexmosphere" style={{ fontSize: "20px" }} onClick={toggleMenu}>Nexmosphere</NavLink>
            <NavLink className="dropdown-item" to="/Products/Netgear" style={{ fontSize: "20px" }} onClick={toggleMenu}>Netgear</NavLink>
            <NavLink className="dropdown-item" to="/Products/Nureva" style={{ fontSize: "20px" }} onClick={toggleMenu}>Nureva</NavLink>
            <NavLink className="dropdown-item" to="/Products/Nvictta" style={{ fontSize: "20px" }} onClick={toggleMenu}>Nvictta</NavLink>
            <NavLink className="dropdown-item" to="/Products/Prysm" style={{ fontSize: "20px" }} onClick={toggleMenu}>Prysm</NavLink>
            <NavLink className="dropdown-item" to="/Products/QSC" style={{ fontSize: "20px" }} onClick={toggleMenu}>QSC</NavLink>
            <NavLink className="dropdown-item" to="/Products/Sennheiser" style={{ fontSize: "20px" }} onClick={toggleMenu}>Sennheiser</NavLink>
            <NavLink className="dropdown-item" to="/Products/Siemon" style={{ fontSize: "20px" }} onClick={toggleMenu}>Siemon</NavLink>
            <NavLink className="dropdown-item" to="/Products/WyreStorm" style={{ fontSize: "20px" }} onClick={toggleMenu}>WyreStorm</NavLink>
            <NavLink className="dropdown-item" to="/Products/Xilica" style={{ fontSize: "20px" }} onClick={toggleMenu}>Xilica</NavLink>
            <NavLink className="dropdown-item" to="/Products/XpoScreens" style={{ fontSize: "20px" }} onClick={toggleMenu}>XpoScreens</NavLink>
          </div>
        </li>

        {/* Other menu items */}
        <li className="nav-item">
          <NavLink className="navlinkstyles" to="/Services" onClick={toggleMenu}>Services</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navlinkstyles" to="/Careers" onClick={toggleMenu}>Careers</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navlinkstyles" to="/EPR" onClick={toggleMenu}>EPR</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="navlinkstyles button_1" to="/Contact" onClick={toggleMenu}>Contact Us</NavLink>
        </li>
      </ul>
    </div>
  )}
      </nav>
    </>
  );
}

export default Navbar;

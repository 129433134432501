import React, { useState }  from 'react';
import './NavbarTop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const NavbarTop = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  
  return (
    <div className='nav-top'>
      <div className='cust-container d-flex justify-content-between w-100'>
        {/* First div aligned to the left */}
        <div className='social-links d-flex align-items-center'>
          <p>Follow us on</p>
          <ul className='d-flex justify-content-start align-items-center'>
            <li className='px-2' style={{ marginTop: "20px" }}>
              <a href="https://in.linkedin.com/company/nvincysolutions">
                <FontAwesomeIcon icon={faLinkedin} color="#E5DDE7" />
              </a>
            </li>
            <li className='px-2' style={{ marginTop: "20px" }}>
              <a href="https://www.youtube.com/@nvincysolutionspvtltd6691">
                <FontAwesomeIcon icon={faYoutube} color="#E5DDE7" />
              </a>
            </li>
          </ul>
        </div>

        <div className='navbar-top-right d-flex align-items-center'>
          <ul className='navi d-flex justify-content-end align-items-center'>
            {/* <li className='px-3' style={{ marginTop: "20px" }}>Support</li> */}
            <div>
      <li className='px-3' style={{ marginTop: "20px" }}>
          <span onClick={toggleModal}>
            <FontAwesomeIcon icon={faSearch} color="#E5DDE7" />
          </span>
        </li>

      {/* Render Modal */}
      <Modal showModal={showModal} toggleModal={toggleModal} />
    </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

const Modal = ({ showModal, toggleModal }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const navigate = useNavigate();

  // Define the list of search items inside the component
  const searchItems = ['Aoto', 'Allsee', 'Audac', 'Audinate', 'BrightSign', 'CatchBox', 'Cyviz', 'EarthWorks', 'Harman', 'Laia', 'MultiBrackets', 'Netgear', 'Nexmosphere', 'Nureva', 'Nviccta', 'QSC', 'Sennheiser', 'Siemon', 'Wyrestorm', 'Xilica', 'XpoScreens', 'Prysm', 'Maxhub'];

  const handleSearchItemClick = (item) => {
    // Redirect to the appropriate route when an item is clicked
    navigate(`/Products/${item}`);  // This will redirect to /nureva or any clicked item

    // Close the modal and reset the search state after navigation
    setSearchTerm('');
    setFilteredSuggestions([]);
    toggleModal();
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase(); // Convert the input to lowercase for comparison
    setSearchTerm(e.target.value); // Store the original value, including case

    // Filter search items but keep their original case in the display
    const suggestions = searchItems
      .filter(item => item.toLowerCase().includes(value)) // Use lowercase comparison for filtering
      .sort((a, b) => {
        if (a.toLowerCase() === value) return -1;
        if (b.toLowerCase() === value) return 1;
        return 0;
      });

    setFilteredSuggestions(suggestions);
  };

  // Reset search when closing the modal
  const handleCloseModal = () => {
    setSearchTerm('');
    setFilteredSuggestions([]);
    toggleModal();
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={handleCloseModal}>
      <div className="modal-contentt" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body" style={{ position: 'relative' }}>
          <input
            className="search-input-top"
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={handleSearch}
            style={{ width: '100%'}}
          />
          <span className="btn-close" onClick={handleCloseModal}>x</span>

          {/* Display suggestions */}
          {filteredSuggestions.length > 0 && (
            <ul className="suggestions-list">
              {filteredSuggestions.map((item, index) => (
                <li key={index} className={item === searchTerm ? 'highlight' : ''} onClick={() => handleSearchItemClick(item)}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};




export default NavbarTop

import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import NavbarTop from './Main_Components/NavbarTop/NavbarTop';
import Navbar from './Main_Components/Navbar/Navbar';

import Footer from './Main_Components/Footer/Footer';
import PrivacyPolicy from './Main_Components/Privacypolicy/Privacypolicy';


const App = () => {

  const LazyHome = React.lazy(() => import('./Main_Components/Home/Home'))
  const LazyAbout = React.lazy(() => import('./Main_Components/About/About'))
  const LazyProducts = React.lazy(() => import('./Main_Components/Products/Products'))
  const LazyNureva = React.lazy(() => import('./Main_Components/Nureva/Nureva'))
  const LazyContact = React.lazy(() => import('./Main_Components/Contact/Contact'))
  const LazyCareers = React.lazy(() => import('./Main_Components/Career/Career'))
  const LazyServices = React.lazy(() => import('./Main_Components/Services/Services'))
  const LazyAoto = React.lazy(() => import('./Main_Components/Aoto/Aoto'))
  const LazyAlsee = React.lazy(() => import('./Main_Components/Alsee/Alsee'))
  const LazyAudac = React.lazy(() => import('./Main_Components/Audac/Audac'))
  const LazyAudinate = React.lazy(() => import('./Main_Components/Audinate/Audinate'))
  const LazyCatchBox = React.lazy(() => import('./Main_Components/CatchBox/CatchBox'))
  const LazyBrightsign = React.lazy(() => import('./Main_Components/BrightSign/BrightSign'))
  const LazyCyviz = React.lazy(() => import('./Main_Components/Cyviz/Cyviz'))
  const LazyEarthWorks = React.lazy(() => import('./Main_Components/EarthWorks/EarthWorks'))
  const LazyHarman = React.lazy(() => import('./Main_Components/Harman/Harman'))
  const LazyLaia = React.lazy(() => import('./Main_Components/Laia/Laia'))
  const LazyNetgear = React.lazy(() => import('./Main_Components/Netgear/Netgear'))
  const LazyNexMosphere = React.lazy(() => import('./Main_Components/NexMosphere/NexMosphere'))
  const LazyXpoScreens = React.lazy(() => import('./Main_Components/XpoScreens/XpoScreens'))
  const LazyXilica = React.lazy(() => import('./Main_Components/Xilica/Xilica'))
  const LazyMultibrackets = React.lazy(() => import('./Main_Components/Microbrackerts/Microbrackerts'))
  const LazyWyreStorm = React.lazy(() => import('./Main_Components/WyreStorm/WyreStorm'))
  const LazySiemon = React.lazy(() => import('./Main_Components/Siemon/Siemon'))
  const LazySennheiser = React.lazy(() => import('./Main_Components/Sennheiser/Sennheiser'))
  const LazyQSC = React.lazy(() => import('./Main_Components/QSC/QSC'))
  const LazyNvictta = React.lazy(() => import('./Main_Components/Nvictta/Nvictta'))
  const LazyMaxhub = React.lazy(() => import('./Main_Components/Maxhub/Maxhub'))
  const LazyPrysm = React.lazy(() => import('./Main_Components/Prysm/Prysm'))
  
  

  return (
    <div>
        <NavbarTop/>
        <Navbar/>
        <Routes>
        <Route exact path="/" element={<React.Suspense fallback="Loading...."><LazyHome /></React.Suspense>} />
        <Route exact path="/About" element={<React.Suspense fallback="Loading...."><LazyAbout /></React.Suspense>} />
        <Route exact path="/Products" element={<React.Suspense fallback="Loading...."><LazyProducts /></React.Suspense>} />
        <Route exact path="/Products/Nureva" element={<React.Suspense fallback="Loading...."><LazyNureva /></React.Suspense>} />
        <Route exact path="/Contact" element={<React.Suspense fallback="Loading...."><LazyContact /></React.Suspense>} />
        <Route exact path="/Careers" element={<React.Suspense fallback="Loading...."><LazyCareers /></React.Suspense>} />
        <Route exact path="/Services" element={<React.Suspense fallback="Loading...."><LazyServices /></React.Suspense>} />
        <Route exact path="/Products/Aoto" element={<React.Suspense fallback="Loading...."><LazyAoto /></React.Suspense>} />
        <Route exact path="/Products/Allsee" element={<React.Suspense fallback="Loading...."><LazyAlsee /></React.Suspense>} />
        <Route exact path="/Products/Audac" element={<React.Suspense fallback="Loading...."><LazyAudac /></React.Suspense>} />
        <Route exact path="/Products/Audinate" element={<React.Suspense fallback="Loading...."><LazyAudinate /></React.Suspense>} />
        <Route exact path="/Products/CatchBox" element={<React.Suspense fallback="Loading...."><LazyCatchBox /></React.Suspense>} />
        <Route exact path="/Products/Brightsign" element={<React.Suspense fallback="Loading...."><LazyBrightsign /></React.Suspense>} />
        <Route exact path="/Products/Cyviz" element={<React.Suspense fallback="Loading...."><LazyCyviz /></React.Suspense>} />
        <Route exact path="/Products/EarthWorks" element={<React.Suspense fallback="Loading...."><LazyEarthWorks /></React.Suspense>} />
        <Route exact path="/Products/Harman" element={<React.Suspense fallback="Loading...."><LazyHarman /></React.Suspense>} />
        <Route exact path="/Products/Laia" element={<React.Suspense fallback="Loading...."><LazyLaia /></React.Suspense>} />
        <Route exact path="/Products/Netgear" element={<React.Suspense fallback="Loading...."><LazyNetgear /></React.Suspense>} />
        <Route exact path="/Products/NexMosphere" element={<React.Suspense fallback="Loading...."><LazyNexMosphere /></React.Suspense>} />
        <Route exact path="/Products/XpoScreens" element={<React.Suspense fallback="Loading...."><LazyXpoScreens /></React.Suspense>} />
        <Route exact path="/Products/Xilica" element={<React.Suspense fallback="Loading...."><LazyXilica /></React.Suspense>} />
        <Route exact path="Products/Multibrackets" element={<React.Suspense fallback="Loading...."><LazyMultibrackets /></React.Suspense>} />
        <Route exact path="/Products/WyreStorm" element={<React.Suspense fallback="Loading...."><LazyWyreStorm /></React.Suspense>} />
        <Route exact path="/Products/Siemon" element={<React.Suspense fallback="Loading...."><LazySiemon /></React.Suspense>} />
        <Route exact path="/Products/Sennheiser" element={<React.Suspense fallback="Loading...."><LazySennheiser /></React.Suspense>} />
        <Route exact path="/Products/QSC" element={<React.Suspense fallback="Loading...."><LazyQSC /></React.Suspense>} />
        <Route exact path="/Products/Nvictta" element={<React.Suspense fallback="Loading...."><LazyNvictta /></React.Suspense>} />
        <Route exact path="/Products/Maxhub" element={<React.Suspense fallback="Loading...."><LazyMaxhub /></React.Suspense>} />
        <Route exact path="/Products/Prysm" element={<React.Suspense fallback="Loading...."><LazyPrysm /></React.Suspense>} />
            <Route path="/Privacypolicy" element={<PrivacyPolicy/>} />
        </Routes>
        <Footer/>
    </div>
  )
}

export default App 








